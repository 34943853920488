import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";

interface TableHeaderProps {
  style?: any;
  writingMode?: "horizontal" | "vertical";
  columnTitles?: string;
  id?: string;
  hideOnMobileResolution?: boolean;
}

interface TableHeaderState {}

export class TableHeader extends PureComponent<TableHeaderProps, TableHeaderState> {
  constructor(props: TableHeaderProps) {
    super(props);
  }

  componentDidMount(): void {
    const tableHeader = ReactDOM.findDOMNode(this) as Element;
    const table = tableHeader?.parentElement;
    if (table && this.props.hideOnMobileResolution === true) {
      table.classList.add("ktableheader_without-title");
    }
  }

  getProps = () => {
    const props: any = _.clone(this.props);
    if (props?.style?.display) {
      delete props.style.display;
    }
    return props;
  };

  render(): ReactNode {
    return <thead {...this.getProps()}></thead>;
  }
}
