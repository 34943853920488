import { Button, Input, InputNumber, Select } from "antd";
import { useEffect, useState } from "react";
import { BooleanFilterTypes, DateFilterTypes, NumberFilterTypes, StringFilterTypes, ValueType } from "./base-table";
import TriStateSwitch from "./tri-state-switch";

const { Option } = Select;

declare let window: any;

const CustomFilterDropdown = ({
  selectedKeys,
  setSelectedKeys,
  confirm,
  clearFilters,
  dataType,
  visible,
  initialFilters
}) => {
  const [searchType, setSearchType] = useState<
    StringFilterTypes | NumberFilterTypes | DateFilterTypes | BooleanFilterTypes
  >(
    dataType === ValueType.String
      ? StringFilterTypes.Contains
      : ValueType.Number
      ? NumberFilterTypes.Equals
      : DateFilterTypes.Equals
  );
  const [searchValue, setSearchValue] = useState<string>(selectedKeys?.value);
  const [searchValue2, setSearchValue2] = useState<string>(selectedKeys?.value2);

  const language = localStorage.getItem("ml");

  const isString = (value: StringFilterTypes | NumberFilterTypes | DateFilterTypes | BooleanFilterTypes) => {
    if (
      value === StringFilterTypes.Contains ||
      value === StringFilterTypes.DoesNotContain ||
      value === StringFilterTypes.Equals ||
      value === StringFilterTypes.DoesNotEqual ||
      value === StringFilterTypes.BeginsWith ||
      value === StringFilterTypes.EndsWith ||
      value === StringFilterTypes.Blank ||
      value === StringFilterTypes.NotBlank
    ) {
      return true;
    }
    return false;
  };

  const isNumber = (value: NumberFilterTypes | StringFilterTypes | DateFilterTypes | BooleanFilterTypes) => {
    if (
      value === NumberFilterTypes.Equals ||
      value === NumberFilterTypes.DoesNotEqual ||
      value === NumberFilterTypes.GreaterThan ||
      value === NumberFilterTypes.GreaterThanOrEqual ||
      value === NumberFilterTypes.LessThan ||
      value === NumberFilterTypes.LessThanOrEqual ||
      value === NumberFilterTypes.Blank ||
      value === NumberFilterTypes.NotBlank ||
      value === NumberFilterTypes.Between
    ) {
      return true;
    }
    return false;
  };

  const isDate = (value: DateFilterTypes | StringFilterTypes | NumberFilterTypes | BooleanFilterTypes) => {
    if (
      value === DateFilterTypes.After ||
      value === DateFilterTypes.Before ||
      value === DateFilterTypes.Between ||
      value === DateFilterTypes.Equals ||
      value === DateFilterTypes.DoesNotEqual ||
      value === DateFilterTypes.Blank ||
      value === DateFilterTypes.NotBlank
    ) {
      return true;
    }
    return false;
  };

  const isBoolean = (value: StringFilterTypes | NumberFilterTypes | DateFilterTypes | BooleanFilterTypes) => {
    if (
      value === BooleanFilterTypes.Equals ||
      value === BooleanFilterTypes.DoesNotEqual ||
      value === BooleanFilterTypes.Blank ||
      value === BooleanFilterTypes.NotBlank
    ) {
      return true;
    }
    return false;
  };

  const isStringType = isString(selectedKeys?.type);
  const isNumberType = isNumber(selectedKeys?.type);
  const isDateType = isDate(selectedKeys?.type);
  const isBooleanType = isBoolean(selectedKeys?.type);

  useEffect(() => {
    if (visible) {
      const searchType = findDataType(dataType);
      const currentType = selectedKeys?.type ?? searchType;
      setSearchType(currentType);
      setSelectedKeys({ ...selectedKeys, type: currentType });
    }
  }, [visible]);

  useEffect(() => {
    if (initialFilters && initialFilters.value && initialFilters.type && initialFilters.value !== searchValue) {
      const searchType = findDataType(dataType);
      setSearchType(searchType);
      setSelectedKeys({ ...selectedKeys, value: initialFilters.value, type: initialFilters.type });
      setSearchValue(initialFilters.value);
      confirm();
    }
  }, []);

  const findDataType = (value: string) => {
    if (value === ValueType.Boolean) {
      return BooleanFilterTypes.Equals;
    }
    if (value === ValueType.String) {
      return StringFilterTypes.Contains;
    }
    if (value === ValueType.Number) {
      return NumberFilterTypes.Equals;
    }
    if (value === ValueType.Date) {
      return DateFilterTypes.Equals;
    }
    return StringFilterTypes.Contains;
  };

  const handleSearch = () => {
    confirm();
  };

  const handleReset = () => {
    setSelectedKeys("");
    const searchType = findDataType(dataType);
    setSearchType(searchType);
    setSearchValue("");
    clearFilters();
    confirm();
  };

  const handleSearchValueChange = (e, secondValue?: boolean) => {
    if (secondValue) {
      setSearchValue2(e.target ? e.target.value : e);
      setSelectedKeys({ value: searchValue, value2: e.target ? e.target.value : e, type: searchType });
      return;
    }
    setSearchValue(e.target ? e.target.value : e);
    setSelectedKeys({ value: e.target ? e.target.value : e, type: searchType });
  };

  const isBlank =
    searchType === StringFilterTypes.Blank ||
    searchType === NumberFilterTypes.Blank ||
    searchType === DateFilterTypes.Blank ||
    searchType === BooleanFilterTypes.Blank;
  const isNotBlank =
    searchType === StringFilterTypes.NotBlank ||
    searchType === NumberFilterTypes.NotBlank ||
    searchType === DateFilterTypes.NotBlank ||
    searchType === BooleanFilterTypes.NotBlank;

  const isDesignTime = window.kuika.isDesignTime;

  return (
    <div style={{ padding: 8 }}>
      <Select
        value={searchType}
        style={{ width: "100%", marginBottom: 8, display: "block" }}
        onChange={(value) => {
          setSearchType(value);
          setSelectedKeys({ value: searchValue, type: value });
        }}
      >
        {dataType === ValueType.String && (
          <Option value={StringFilterTypes.Contains}>{language === "tr_TR" ? "İçerir" : "Contains"}</Option>
        )}
        {dataType === ValueType.String && (
          <Option value={StringFilterTypes.DoesNotContain}>
            {language === "tr_TR" ? "İçermez" : "Does Not Contain"}
          </Option>
        )}
        {dataType === ValueType.String && (
          <Option value={StringFilterTypes.Equals}>{language === "tr_TR" ? "Eşittir" : "Equals"}</Option>
        )}
        {dataType === ValueType.String && (
          <Option value={StringFilterTypes.DoesNotEqual}>
            {language === "tr_TR" ? "Eşit Değildir" : "Does Not Equal"}
          </Option>
        )}
        {dataType === ValueType.String && (
          <Option value={StringFilterTypes.BeginsWith}>{language === "tr_TR" ? "Başlar" : "Begins With"}</Option>
        )}
        {dataType === ValueType.String && (
          <Option value={StringFilterTypes.EndsWith}>{language === "tr_TR" ? "Biter" : "Ends With"}</Option>
        )}
        {dataType === ValueType.String && (
          <Option value={StringFilterTypes.Blank}>{language === "tr_TR" ? "Boş" : "Blank"}</Option>
        )}
        {dataType === ValueType.String && (
          <Option value={StringFilterTypes.NotBlank}>{language === "tr_TR" ? "Boş Değildir" : "Not Blank"}</Option>
        )}
        {dataType === ValueType.Number && (
          <Option value={NumberFilterTypes.Equals}>{language === "tr_TR" ? "Eşittir" : "Equals"}</Option>
        )}
        {dataType === ValueType.Number && (
          <Option value={NumberFilterTypes.DoesNotEqual}>
            {language === "tr_TR" ? "Eşit Değildir" : "Does Not Equal"}
          </Option>
        )}
        {dataType === ValueType.Number && (
          <Option value={NumberFilterTypes.GreaterThan}>{language === "tr_TR" ? "Büyüktür" : "Greater Than"}</Option>
        )}
        {dataType === ValueType.Number && (
          <Option value={NumberFilterTypes.GreaterThanOrEqual}>
            {language === "tr_TR" ? "Büyük Eşittir" : "Greater Than or Equal"}
          </Option>
        )}
        {dataType === ValueType.Number && (
          <Option value={NumberFilterTypes.LessThan}>{language === "tr_TR" ? "Küçüktür" : "Less Than"}</Option>
        )}
        {dataType === ValueType.Number && (
          <Option value={NumberFilterTypes.LessThanOrEqual}>
            {language === "tr_TR" ? "Küçük Eşittir" : "Less Than or Equal"}
          </Option>
        )}
        {dataType === ValueType.Number && (
          <Option value={NumberFilterTypes.Blank}>{language === "tr_TR" ? "Boş" : "Blank"}</Option>
        )}
        {dataType === ValueType.Number && (
          <Option value={NumberFilterTypes.NotBlank}>{language === "tr_TR" ? "Boş Değildir" : "Not Blank"}</Option>
        )}
        {dataType === ValueType.Date && (
          <Option value={DateFilterTypes.After}>{language === "tr_TR" ? "Tarihinden Sonra" : "After Date"}</Option>
        )}
        {dataType === ValueType.Date && (
          <Option value={DateFilterTypes.Before}>{language === "tr_TR" ? "Tarihinden Önce" : "Before Date"}</Option>
        )}
        {dataType === ValueType.Date && (
          <Option value={DateFilterTypes.Between}>
            {language === "tr_TR" ? "Tarihleri Arasında" : "Between Dates"}
          </Option>
        )}
        {dataType === ValueType.Date && (
          <Option value={DateFilterTypes.Equals}>{language === "tr_TR" ? "Eşittir" : "Equals"}</Option>
        )}
        {dataType === ValueType.Date && (
          <Option value={DateFilterTypes.DoesNotEqual}>
            {language === "tr_TR" ? "Eşit Değildir" : "Does Not Equal"}
          </Option>
        )}
        {dataType === ValueType.Date && (
          <Option value={DateFilterTypes.Blank}>{language === "tr_TR" ? "Boş" : "Blank"}</Option>
        )}
        {dataType === ValueType.Date && (
          <Option value={DateFilterTypes.NotBlank}>{language === "tr_TR" ? "Boş Değildir" : "Not Blank"}</Option>
        )}
        {dataType === ValueType.Boolean && (
          <Option value={BooleanFilterTypes.Equals}>{language === "tr_TR" ? "Eşittir" : "Equals"}</Option>
        )}
        {dataType === ValueType.Boolean && (
          <Option value={BooleanFilterTypes.DoesNotEqual}>
            {language === "tr_TR" ? "Eşit Değildir" : "Does Not Equal"}
          </Option>
        )}
        {dataType === ValueType.Boolean && (
          <Option value={BooleanFilterTypes.Blank}>{language === "tr_TR" ? "Boş" : "Blank"}</Option>
        )}
        {dataType === ValueType.Boolean && (
          <Option value={BooleanFilterTypes.NotBlank}>{language === "tr_TR" ? "Boş Değildir" : "Not Blank"}</Option>
        )}
        {/* <Option value={DateFilterTypes.Between}>Between Dates</Option> */}
      </Select>
      {isString(searchType) && !isBlank && !isNotBlank && (
        <Input
          placeholder={language === "tr_TR" ? "Filtrele..." : "Filter..."}
          value={searchValue}
          onChange={(e) => handleSearchValueChange(e)}
          style={{ width: "100%", marginBottom: 8, display: "block" }}
          autoFocus
          readOnly={isDesignTime}
        />
      )}
      {isNumber(searchType) && !isBlank && !isNotBlank && (
        <InputNumber
          placeholder={language === "tr_TR" ? "Filtrele..." : "Filter..."}
          value={searchValue}
          onChange={(e) => handleSearchValueChange(e)}
          style={{ width: "100%", marginBottom: 8, display: "block" }}
          autoFocus
          readOnly={isDesignTime}
        />
      )}
      {isDate(searchType) && !isBlank && !isNotBlank && (
        <Input
          type="date"
          placeholder={language === "tr_TR" ? "Filtrele..." : "Filter..."}
          value={searchValue}
          onChange={(e) => handleSearchValueChange(e)}
          style={{ width: "100%", marginBottom: 8, display: "block" }}
          autoFocus
          readOnly={isDesignTime}
        />
      )}
      {searchType === DateFilterTypes.Between && (
        <Input
          type="date"
          placeholder={language === "tr_TR" ? "Filtrele..." : "Filter..."}
          value={searchValue2}
          onChange={(e) => handleSearchValueChange(e, true)}
          style={{ width: "100%", marginBottom: 8, display: "block" }}
          autoFocus
        />
      )}
      {isBoolean(searchType) && !isBlank && !isNotBlank && (
        <TriStateSwitch
          value={searchValue || null}
          onChange={(value) => {
            setSearchValue(value);
            setSelectedKeys({ value, type: searchType });
          }}
          language={language}
        />
      )}

      <Button type="primary" onClick={handleSearch} size="small" style={{ width: 90, marginRight: 8 }}>
        {language === "tr_TR" ? "Filtrele" : "Filter"}
      </Button>
      <Button onClick={handleReset} size="small" style={{ width: 90 }}>
        {language === "tr_TR" ? "Sıfırla" : "Reset"}
      </Button>
    </div>
  );
};

export default CustomFilterDropdown;
