import _ from "lodash";
import { ReactNode } from "react";
import { KComponentFunctions } from "../../../../../shared/utilty/component-functions";
import { TableRow, TableRowProps, TableRowState } from "../table/table-row";

export type FlexGridItemProps = TableRowProps;

type FlexGridItemState = TableRowState;

export class FlexGridItem extends TableRow<FlexGridItemProps, FlexGridItemState> {
  constructor(props: FlexGridItemProps) {
    super(props);
    this.state = { mouseOver: false };
  }

  handleRowClick = (e: any) => {
    if (
      this.props.rowComponent &&
      this.props.rowComponent.props.onClick &&
      this.props.rowIndex !== undefined &&
      this.props.rowData
    ) {
      if (KComponentFunctions.isButtonElement(e.target) === true) {
        return;
      }
      if (KComponentFunctions.isImgElement(e.target) === true) {
        return;
      }
      this.props.rowComponent.props.onClick(e, this.props.rowIndex, this.props.rowData);
    }
  };

  getStyleMapping = () => {
    const _parentStyleObject = _.clone(this.getStyle());
    const forbiddenStyleKeyList = ["border", "background", "height", "width", "boxShadow"];
    for (const type in _parentStyleObject) {
      forbiddenStyleKeyList.forEach((styleKey) => {
        if (type.includes(styleKey)) {
          delete _parentStyleObject[type];
        }
      });
    }
    return _parentStyleObject;
  };

  render(): ReactNode {
    return (
      <>
        <div
          {...this.props}
          onClick={this.handleRowClick}
          style={this.getStyleMapping()}
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOut}
        />
      </>
    );
  }
}
