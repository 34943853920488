import { Space } from "antd";
import { Guid } from "guid-typescript";
import _ from "lodash";
import React, { PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";

interface VerticalStackProps {
  style: any;
  size?: number | string;
}

interface VerticalStackState {
  uniqueKey: Guid;
}

class VerticalStack extends PureComponent<VerticalStackProps & CommonProps, VerticalStackState> {
  private memoizedDynamicCssResult = "";

  constructor(props: VerticalStackProps) {
    super(props);
    this.state = {
      uniqueKey: Guid.create()
    };
  }

  componentDidMount() {
    this.setDynamicStyle();
  }

  componentDidUpdate() {
    this.setDynamicStyle();
  }

  getProps = () => {
    const props: any = _.clone(this.props);
    if (props.children) {
      delete props.children;
    }
    if (props.style) {
      delete props.style;
    }
    if (props.size < 0) props.size = 0;
    return props;
  };

  getStyleProp = () => {
    let style: any = {};

    if (this.props.style) {
      style = _.clone(this.props.style);
    }
    style.height = "auto";
    style.width = "100%";
    return style;
  };

  setDynamicStyle = () => {
    const uniquekey = this.state.uniqueKey?.toString();
    if (!uniquekey) {
      return;
    }
    const isDesignTime = window.kuika?.isDesignTime;
    if (this.memoizedDynamicCssResult !== "" && !isDesignTime) {
      return this.memoizedDynamicCssResult;
    }
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(uniquekey) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML} 
        ${generatedCss}`;
      this.memoizedDynamicCssResult = generatedCss;
    }
  };

  getDynamicCss = (): string => {
    const className: string = this.getClassName();
    if (!className || className.length === 0) {
      return "";
    }
    let result = "";

    const { size } = this.props;
    if (size && size < 0) {
      result += `.${className.trim()}>.ant-space-item:not(:first-of-type) {
          margin-top: ${size}px !important;
        }`;
    }
    return result;
  };

  getClassName = () => {
    let result = "";
    if (this.props.className) {
      result = this.props.className;
    }
    if (!this.state.uniqueKey) {
      return result;
    }
    result = `${result} kspace_${this.state.uniqueKey.toString().substring(0, 8)}`;
    return result;
  };

  render(): ReactNode {
    return (
      <>
        <Space className={this.getClassName()} style={this.getStyleProp()} {...this.getProps()} direction="vertical">
          {React.Children.map(this.props.children, (child: any) => {
            if (child?.props?.visibility === "hidden" || child?.props?.children?.props?.visibility === "hidden") {
              return null;
            }
            return <>{child}</>;
          })}
        </Space>
      </>
    );
  }
}

const verticalStack = withCommonEvents(VerticalStack);
export { verticalStack as VerticalStack };
