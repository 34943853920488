import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import _ from "lodash";
import React, { PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import { ValueType } from "../base-table";
import TriStateSwitch from "../tri-state-switch";

interface TableHeaderCellProps {
  style?: any;
  columnStyle?: any;
  textDirection?: string;
  columnSearchText?: string;
  setColumnSearchText?: (key, value) => void;
  title?: string;
  children?: any;
  searchable?: boolean;
  type?: ValueType;
  dataValue?: string;
}

interface TableHeaderCellState {
  searchValue: any;
}

declare let window: any;

export class TableHeaderCell extends PureComponent<TableHeaderCellProps, TableHeaderCellState> {
  constructor(props: TableHeaderCellProps) {
    super(props);
    this.state = {
      searchValue: ""
    };
  }

  getStyle = (): any => {
    let style: any = {};
    if (this.props.style) {
      style = _.clone(this.props.style);
    }
    if (
      !style.border &&
      !style.borderColor &&
      !style.borderTop &&
      !style.borderBottom &&
      !style.borderRight &&
      !style.borderLeft &&
      !style.borderWidth
    ) {
      style.borderWidth = 0;
    }
    if (!style.backgroundColor && !style.background) {
      style.backgroundColor = "transparent";
    }
    if (this.props.columnStyle && this.props.columnStyle.textAlign) {
      style.textAlign = this.props.columnStyle.textAlign;
    }
    if (this.props.textDirection && this.props.textDirection !== "Default") {
      if (style.textAlign === "-webkit-left") {
        style.verticalAlign = this.props.textDirection === "Rotate Up" ? "bottom" : "top";
      } else if (style.textAlign === "-webkit-right") {
        style.verticalAlign = this.props.textDirection === "Rotate Up" ? "top" : "bottom";
      } else {
        style.verticalAlign = "middle";
      }
      style.textAlign = "-webkit-center";
    }
    if (this.props.columnStyle && this.props.columnStyle.padding) {
      style.paddingLeft = this.props.columnStyle.padding;
      style.paddingRight = this.props.columnStyle.padding;
    }
    if (this.props.columnStyle && this.props.columnStyle.paddingLeft) {
      style.paddingLeft = this.props.columnStyle.paddingLeft;
    }
    if (this.props.columnStyle && this.props.columnStyle.paddingRight) {
      style.paddingRight = this.props.columnStyle.paddingRight;
    }
    if (style?.display) {
      delete style.display;
    }
    return style;
  };

  componentDidMount(): void {
    if (this.props.columnSearchText && this.props.columnSearchText[this.props.dataValue]) {
      this.setState({ searchValue: this.props.columnSearchText[this.props.dataValue] });
    } else if (
      (this.props.columnSearchText &&
        this.props.type === ValueType.Boolean &&
        this.props.columnSearchText[this.props.dataValue] === null) ||
      this.props.columnSearchText[this.props.dataValue] === false ||
      this.props.columnSearchText[this.props.dataValue] === true ||
      this.props.columnSearchText[this.props.dataValue] === "false" ||
      this.props.columnSearchText[this.props.dataValue] === "true"
    ) {
      this.setState({ searchValue: this.props.columnSearchText[this.props.dataValue] });
    }
    const tableHeader = ReactDOM.findDOMNode(this) as Element;
    const { style } = this.props;
    if (!tableHeader) return;
    const antTableFilterTrigger = tableHeader.querySelector(".ant-table-filter-trigger");
    const antTableColumnSorter = tableHeader.querySelector(".ant-table-column-sorter");
    if (style.color) {
      if (antTableFilterTrigger) {
        antTableFilterTrigger.setAttribute("style", `color: ${style.color}`);
      }
      if (antTableColumnSorter) {
        antTableColumnSorter.setAttribute("style", `color: ${style.color}`);
      }
    }
  }

  handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchValue: e.target.value });
  };

  handleSearchBlur = () => {
    const { setColumnSearchText, dataValue } = this.props;
    if (setColumnSearchText && dataValue) {
      setColumnSearchText(dataValue, this.state.searchValue);
    }
  };

  renderInput = () => {
    const { type } = this.props;
    const language = localStorage.getItem("ml");
    const { searchValue } = this.state;

    const isDesignTime = window.kuika.isDesignTime;

    const inputType = type === ValueType.Number ? "number" : type === ValueType.Date ? "date" : "text";
    if (type === ValueType.Boolean) {
      return (
        <TriStateSwitch
          value={
            this.props.columnSearchText[this.props.dataValue] === true
              ? true
              : this.props.columnSearchText[this.props.dataValue] === false
              ? false
              : null
          }
          onChange={(value) => {
            this.setState({ searchValue: value }, () => {
              this.handleSearchBlur();
            });
          }}
          language={language}
        />
      );
    }
    return (
      <Input
        type={inputType}
        placeholder={language === "tr_TR" ? "Ara..." : "Search..."}
        value={this.state.searchValue}
        onClick={(e) => e.stopPropagation()}
        onChange={this.handleSearchChange}
        onBlur={this.handleSearchBlur}
        onPressEnter={(e) => {
          e.stopPropagation();
          this.handleSearchBlur();
        }}
        suffix={
          <SearchOutlined
            onClick={(e) => {
              e.stopPropagation();
              this.handleSearchBlur();
            }}
          />
        }
        readOnly={isDesignTime}
      />
    );
  };

  render(): ReactNode {
    const hasSearchable = typeof this.props.children[1] === "object"; 
    return (
      <th {...this.props} style={this.getStyle()}>
        {...this.props.children}
        {this.props.title && this.props.title !== "" && this.props.searchable && hasSearchable && this.renderInput()}
      </th>
    );
  }
}
